import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  HomeOutlined, 
  ReadOutlined, 
  OrderedListOutlined, 
  TeamOutlined, 
  SwapOutlined,
  UserAddOutlined,
  SkinOutlined,
  StarOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  FormOutlined,
  HighlightOutlined,
  ImportOutlined,
  SettingOutlined
} from '@ant-design/icons';

import navigationConsts from 'constants/navigationConsts';

import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

const useNavigation = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [activeNavItem, setActiveNavItem] = useState([]);

  const { adminData } = useAdmin();
  const { userData, loggedOut } = useUser();

  const getUserMenu = ({ handleItemSelect = null }) => {

    return [
      {
        key: navigationConsts.HOME,
        label: "Home",
        icon: <HomeOutlined />,
        visible: 1, // https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
        onClick: () => { navigate('/'); handleItemSelect &&  handleItemSelect() }
      },
      {
        key: `team/${userData?.teamId}`,
        label: "My Team",
        icon: <SkinOutlined />,
        visible: ! loggedOut && userData?.hasOwnProperty('teamId') ? 1 : 0,
        onClick: () => { navigate(`team/${userData.teamId}`); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.RULES,
        label: "Rules",
        icon: <ReadOutlined />,
        visible: 1,
        onClick: () => { navigate('rules'); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.TEAM_STANDINGS,
        label: "Team Standings",
        icon: <OrderedListOutlined />,
        visible: 1,
        onClick: () => { navigate('team-standings'); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.PLAYER_STANDINGS,
        label: "Player Standings",
        icon: <TeamOutlined />,
        visible: 1,
        onClick: () => { navigate('player-standings'); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.TEAM_OF_THE_WEEK,
        label: "Team of the Week",
        icon: <StarOutlined />,
        visible: 1,
        onClick: () => { navigate('team-of-the-week'); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.TRANSFERS,
        label: "Transfers",
        icon: <SwapOutlined />,
        onClick: () => { navigate('transfers'); handleItemSelect && handleItemSelect() },
        visible: ! loggedOut && userData?.hasOwnProperty('teamId') && adminData.transferWindowIsOpen() ? 1 : 0
      },
      {
        key: navigationConsts.CREATE_TEAM,
        label: "Create Team",
        icon: <SwapOutlined />,
        visible: (! (! loggedOut && userData && userData?.hasOwnProperty('teamId') && Object.keys(userData).length > 0)) ? 1 : 0,
        onClick: () => { navigate('create-team'); handleItemSelect && handleItemSelect() }
      },
      {
        key: navigationConsts.CREATE_ACCOUNT,
        label: "Create Account",
        icon: <UserAddOutlined />,
        visible: loggedOut && userData && Object.keys(userData).length === 0 ? 1 : 0,
        onClick: () => { navigate('new-user'); handleItemSelect && handleItemSelect() }
      },
      {
        key: `adminParent-${navigationConsts.ADMIN_HOME}`,
        label: "Admin",
        icon: <SettingOutlined />,
        visible: ! loggedOut && userData?.admin ? 1 : 0,
        children: getAdminMenu({ handleItemSelect: handleItemSelect }),
      }
    ].filter(item => item.visible);
  }

  const getAdminMenu = ({ handleItemSelect = null }) => {
    return [
      {
          key: navigationConsts.ADMIN_HOME,
          visible: 1,
          label: 'Admin Home',
          icon: <HomeOutlined />,
          onClick: () => { navigate('/admin'); handleItemSelect && handleItemSelect() }
      },
      {
          key: navigationConsts.ADMIN_ADD_EDIT_PLAYERS,
          visible: 1,
          label: 'Add/Edit Players',
          icon: <UsergroupAddOutlined />,
          onClick: () => { navigate('/admin/edit-players'); handleItemSelect && handleItemSelect() }
      },
      {
          key: navigationConsts.ADMIN_MANAGE_USERS,
          visible: 1,
          label: 'Manage Users',
          icon: <UserOutlined />,
          onClick: () => { navigate('/admin/manage-users'); handleItemSelect && handleItemSelect() }
      },
      {
          key: navigationConsts.ADMIN_IMPORTED_MATCHES,
          visible: 1,
          label: 'Imported Matches',
          icon: <FormOutlined />,
          onClick: () => { navigate('/admin/imported-matches'); handleItemSelect && handleItemSelect() }
      },
      {
          key: navigationConsts.ADMIN_POINTS_STRUCTURE,
          visible: adminData.seasonHasStarted() ? 0 : 1,
          label: 'Edit Points Structure',
          icon: <HighlightOutlined />,
          onClick: () => { navigate('/admin/edit-points'); handleItemSelect && handleItemSelect() }
      },
      {
          key: navigationConsts.ADMIN_IMPORT_PLAYERS,
          visible: adminData.hasAutoUpdate() ? 1 : 0,
          icon: <ImportOutlined />,
          onClick: () => { navigate('/admin/import-players'); handleItemSelect && handleItemSelect() },
          label: 'Import Players'
      },
      {
          key: navigationConsts.ADMIN_MANUAL_IMPORT_MATCH,
          visible: adminData.doesNotHaveAutoUpdate() ? 1 :0,
          icon: <ImportOutlined />,
          onClick: () => { navigate('/admin/manual-import-match'); handleItemSelect && handleItemSelect() } ,
          label: 'Manually Import Match'
      }
    ].filter(item => item.visible);
  };

  useEffect(() => {
    setActiveNavItem([location.pathname.substring(1)]);
  }, [location]);

    return [
      activeNavItem,
      { 
        getUserMenu: getUserMenu,
        getAdminMenu: getAdminMenu
      }
    ];
};

export default useNavigation;