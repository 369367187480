import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { 
    Row, 
    Col, 
    Button,
    Card, 
    Form, 
    DatePicker, 
    Switch, 
    Tooltip, 
    message,
    Select,
    Popconfirm,
    Menu,
    InputNumber,
    theme,
    Alert,
    ColorPicker,
    Typography
} from 'antd';
import { 
    CheckOutlined, 
    CloseOutlined, 
    InfoOutlined, 
    QuestionCircleOutlined
} from '@ant-design/icons';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import styles from './styles';

import { ImportedFixtures, TeamEntryFunds, UploadLogo } from './components';

import useAdmin from "hooks/useAdmin";
import useNavigation from "hooks/useNavigation";

import { PageTitle, Loading } from 'components';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

const { useToken } = theme;

const { Paragraph } = Typography;

const Admin = () => {

    const [activeNavItem, { getAdminMenu }] = useNavigation();

    const { adminData, mutateAdmin } = useAdmin();
    const { token } = useToken();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("");
    const [introText, setIntroText] = useState("");
    const [adminIsSaving, setAdminIsSaving] = useState(false);
    const [cacheIsClearing, setcacheIsClearing] = useState(false);
    const [seasonIsEnding, setSeasonIsEnding] = useState(false);
    const [admin, setAdmin] = useState([]);

    const getAdminForAdminPage = async () => {
        try {
            const admin = await api.admin.getAdminForAdminPage();

            setAdmin(admin.data);
            setColor(admin.data.color);
            setIntroText(admin.data.introText);
            setLoading(false);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }
    }

    const updateColor = async (newColorObject, hexColor) => {
        console.log('bewColor', hexColor);
        setColor(hexColor);
        mutateAdmin({ color: hexColor }, false); // should not revalidate
    }

    useEffect(() => {
        getAdminForAdminPage();
    }, []);

    const handleSubmit = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setAdminIsSaving(true);

                const { 
                    seasonStart, 
                    transferWindow, 
                    canEdit, 
                    gameweekAdvanceDay, 
                    gameweekAdvanceTime,
                    autoPriceChange,
                    transferDeduct
                } = values;

                try {
                    await api.admin.updateAdmin({
                        introText: introText, 
                        primaryColor: color,
                        seasonStart: seasonStart?.format('YYYY-MM-DD') || undefined,
                        transferWindow, 
                        canEdit,
                        gameweekAdvanceDay,
                        gameweekAdvanceTime,
                        autoPriceChange,
                        transferDeduct
                    });

                    mutateAdmin({
                        color: color,
                        introText: introText,
                        canEdit,
                        transferWindow,
                        seasonStart
                    });

                    message.success("Changes Saved");

                } catch (error) {
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));

                    console.error('ERROR_21:', error);
                }

                setAdminIsSaving(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });

            setAdminIsSaving(false);
    }

    if (loading) {
        return <Loading />;
    }

    const clearCache = async () => {
        setcacheIsClearing(true);
        try {
            await api.admin.clearCache();
            message.success("Cache cleared");
            setcacheIsClearing(false);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            setcacheIsClearing(false);
            console.error('ERROR_22:', error);
        }
    }

    const endSeason = async () => {
        setSeasonIsEnding(true);
        try {
            await api.admin.endSeason();
            message.success("Season ended");
            setSeasonIsEnding(false);

            mutateAdmin({ seasonEnded: true }, false); // should not revalidate
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            setSeasonIsEnding(false);
            console.error('ERROR_22:', error);
        }
    }

    return (
        <>
            <PageTitle title="Admin" />

            {adminData.seasonHasEnded() && (
                <>
                <Row style={{ marginBottom: 24 }}>
                    <Col xs={24}>
                        <Alert message="Season has ended. Gameweeks will not advance and no more match data can be added." type="warning" showIcon />
                    </Col>
                </Row>
            

                <Alert 
                    message="Fancy another thrilling season of Fantasy Club Cricket? 🚀"
                    description={
                        <>
                            <Paragraph style={{ marginBottom: 0 }}>Head over to our main website to sign up again for 2025. The site will be reset and you'll be ready to go again.</Paragraph>
                            <Paragraph>Don't worry - when the new site is processed all your existing players and admin changes will be retained.</Paragraph>
                            <Button type='primary' href='https://fantasyclubsports.co.uk/sign-up' target='_blank'>Sign Up for 2025</Button>
                        </>
                    } 
                    type="info" 
                    showIcon
                    action={
                        <Button size="small" type="text" href="https://www.fantasyclubsports.co.uk/whats-new" target="_blank">
                            What's New
                        </Button>
                    }
                    />
                </>
            )}

            <Row style={{ marginBottom: 24 }}>
                <Col xs={24}>
                    <Menu 
                        mode="horizontal" 
                        items={getAdminMenu({})} 
                        selectedKeys={activeNavItem} 
                        />
                </Col>
            </Row>
                
            <Row gutter={{ md: 24 }}>

                <Col xs={24} lg={16}>
                    <Card style={styles.card}>
                        <Row type="flex">
                            <Col xs={24}>
                                <Form form={form} layout='vertical'>
                                    <Form.Item label="Intro Text" name="introText">
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            config={{
                                                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'numberedList', 'bulletedList' ],
                                                heading: {
                                                    options: [
                                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                                                    ]
                                                }
                                            }}
                                            isReadOnly={adminIsSaving}
                                            data={admin.introText}

                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setIntroText(data);
                                            } }
                                        />
                                    </Form.Item>

                                    <Form.Item 
                                        label="Primary Colour" 
                                        name="primaryColor" 
                                        initialValue={color}
                                        rules={[{required: true, message: 'Primary Colour cannot be empty'}]}
                                        >
                                        <ColorPicker 
                                            showText 
                                            defaultValue={color}
                                            onChange={updateColor}
                                            disabled={adminIsSaving} 
                                            />
                                    </Form.Item>

                                    {adminData.seasonHasNotStarted() && (
                                        <Form.Item 
                                            label="Season Start Date" 
                                            name="seasonStart" 
                                            initialValue={dayjs(admin.seasonStart, 'YYYY-MM-DD')}
                                            rules={[{required: true, message: 'Season Start Date cannot be empty'}]}
                                            >
                                            <DatePicker 
                                                disabled={adminIsSaving} 
                                                style={{width: '100%'}}
                                                allowClear={false}
                                                format='ddd DD MMM YYYY'
                                                />
                                        </Form.Item>
                                    )}

                                    {adminData.seasonHasNotStarted() && (
                                        <Form.Item 
                                            label="Additional Weekly Transfer Cost" 
                                            name="transferDeduct"
                                            initialValue={admin.transferDeduct}
                                            rules={[{required: true, message: 'Additional weekly transfers cannot be empty'}]}
                                            >
                                            <InputNumber
                                                style={{width: '100%'}}
                                                disabled={adminIsSaving}
                                                />
                                        </Form.Item>
                                    )}

                                    <Form.Item label="Transfer Window Open" name="transferWindow" initialValue={admin.transferWindow}>
                                        <Switch 
                                            disabled={adminIsSaving}
                                            defaultChecked={admin.transferWindow} 
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />} 
                                            />
                                    </Form.Item>

                                    <Form.Item label="Allow Captain Changes" name="canEdit" initialValue={admin.canEdit}>
                                        <Switch 
                                            disabled={adminIsSaving}
                                            defaultChecked={admin.canEdit} 
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}  
                                            />
                                    </Form.Item>

                                    <Form.Item 
                                        label="Auto Player Price Increase/Decrease" 
                                        name="autoPriceChange"
                                        initialValue={admin.autoPriceChange}
                                        tooltip="When enabled, player values will automatically fluctuate each week based on performance"
                                        >
                                        <Switch 
                                            disabled={adminIsSaving}
                                            defaultChecked={admin.autoPriceChange} 
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />} 
                                            />
                                    </Form.Item>

                                    <Form.Item
                                        label="Logo"
                                        name="logo"
                                        tooltip="Allowed filetypes: png, jpeg, jpg. Max filesize: 200kb">
                                        <UploadLogo 
                                            isSaving={adminIsSaving} 
                                            initialLogo={admin.logo}
                                            />
                                    </Form.Item>

                                    {adminData.seasonHasNotStarted() && (
                                        <Form.Item label="Game Week Advances">
                                            <Form.Item 
                                                name="gameweekAdvanceDay"
                                                initialValue={admin.gameweekAdvanceDay}
                                                style={{
                                                    display: 'inline-block',
                                                    width: 'calc(50% - 8px)',
                                                }}>
                                                    <Select
                                                        disabled={adminIsSaving}
                                                        options={[
                                                            {
                                                                value: 1,
                                                                label: 'Monday',
                                                            },
                                                            {
                                                                value: 2,
                                                                label: 'Tuesday',
                                                            },
                                                            {
                                                                value: 3,
                                                                label: 'Wednesday',
                                                            },
                                                            {
                                                                value: 4,
                                                                label: 'Thursday',
                                                            },
                                                            {
                                                                value: 5,
                                                                label: 'Friday',
                                                            },
                                                            {
                                                                value: 6,
                                                                label: 'Saturday',
                                                            },
                                                            {
                                                                value: 0,
                                                                label: 'Sunday',
                                                            },
                                                        ]}
                                                        />
                                            </Form.Item>
                                            <Form.Item
                                                name="gameweekAdvanceTime"
                                                initialValue={admin.gameweekAdvanceTime}
                                                style={{
                                                    display: 'inline-block',
                                                    width: 'calc(50% - 8px)',
                                                    marginLeft: 16
                                                }}>
                                                <Select
                                                        disabled={adminIsSaving}
                                                        options={Array.from(Array(24).keys()).map(hour => (
                                                            {
                                                                value: hour,
                                                                label: `${hour}:00`
                                                            }
                                                        ))}
                                                        />
                                            </Form.Item>
                                        </Form.Item>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                        
                        <Row type="flex" align="middle" justify="space-between">
                                
                            <div style={styles.destructiveButtonContainer}>
                                <Button.Group>
                                    {/* <Popconfirm title="Are you sure you wish to pause the current season?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                        <Button type="dashed" disabled={adminIsSaving}>Pause Season</Button>
                                    </Popconfirm> */}
                                    <Popconfirm 
                                        title="Are you sure you wish to end the season? This cannot be undone." 
                                        icon={<QuestionCircleOutlined style={{ color: token.colorError }} />}
                                        onConfirm={endSeason}
                                        disabled={adminData.seasonHasEnded()}
                                        >
                                        <Button 
                                            type="dashed" 
                                            danger 
                                            disabled={adminData.seasonHasEnded()}
                                            loading={seasonIsEnding}
                                            size='small'
                                            >
                                            End Season
                                        </Button>
                                    </Popconfirm>
                                    <Popconfirm 
                                        title="Please note clearing cache may cause your site to slow down temporarily." 
                                        icon={<QuestionCircleOutlined />}
                                        onConfirm={clearCache}
                                        clearCache={cacheIsClearing}
                                        >
                                        <Button 
                                            type="dashed" 
                                            disabled={cacheIsClearing}
                                            loading={cacheIsClearing}
                                            size='small'
                                            >
                                            Clear cache
                                        </Button>
                                    </Popconfirm>
                                </Button.Group>
                            </div>

                            <Button 
                                loading={adminIsSaving} 
                                type="primary" 
                                htmlType="submit" 
                                onClick={handleSubmit}
                                >
                                Save
                            </Button>
                        </Row>
                    </Card>
                </Col>
                    <Col xs={24} lg={8}>
                        
                        <Card 
                            style={styles.card}
                            title="Imported Fixtures"
                            >
                            <ImportedFixtures />
                        </Card>
                        
                        {adminData.hasEntryFeeCollection() && (
                            <Card 
                                style={styles.card}
                                title="Team Entry Funds"
                                extra={
                                    <Tooltip 
                                        placement="left" 
                                        title="You can request to withdraw the available funds any time after the  season has started, just drop an email to info@fantasyclubcricket.co.uk containing bank details (account name, number and sort code)">
                                        <Button size="small" shape="circle" icon={<InfoOutlined />} />
                                    </Tooltip>
                                }
                                >
                                <TeamEntryFunds />
                            </Card>
                        )}
                        
                    </Col>
            </Row>
        </>
    )
}

export default Admin;