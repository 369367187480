import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Typography, Table, Result, theme, Space, Row, Col, Divider } from 'antd';
import { 
    CopyrightOutlined,
    EyeInvisibleOutlined,
    StarFilled
} from '@ant-design/icons';

import { Loading, FccSection } from 'components';
import { api } from 'api';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import useAdmin from "hooks/useAdmin";

const { Text, Title } = Typography;
const { useToken } = theme;

const TeamOfTheWeek = () => {

  const { adminData } = useAdmin();
  const { token } = useToken();

  const changePageTitle = useChangePageTitle();

  const [teamDataLoading, setTeamDataLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const getTeamData = async () => {

    setTeamDataLoading(true);

    try {
      const result = await api.team.getTeamOfTheWeekData();

      setTeamData(result.data);

      const totalPoints = result.data.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.weekPoints
        },
        0,
      );

      setTotalPoints(totalPoints);

      setTeamDataLoading(false);
    } catch (error) {
      setTeamDataLoading(false);
    }
  }

  const columns = [
    {
        title: '',
        dataIndex: 'positionName',
        render: (text, record) => adminData.sportIsCricket() && record.positionId ? 
            (<img src={`/images/${record.positionId}.svg`} width="30px" height="30px" />) : 
            <Text type="secondary">{record.positionName}</Text>,
        align: 'right'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => (
          <>
            <Link to={`/player/${record.id}`}>{record.name}</Link>
            {record.teamOfTheWeek && <StarFilled style={{marginLeft: 2, color: token.colorStar }} />}
          </>
        ),
    },
    {
        title: 'Value',
        dataIndex: 'adjustedValue',
        render: (text) => text ? `${adminData.currency()}${text}m` : ""
    },
    {
        title: `Week Points`,
        dataIndex: 'weekPoints',
        render: (text) => text ? text : 0,
    },
  ];

  useEffect(() => {
    changePageTitle('Team of the Week');
    getTeamData();
  }, []);

  if (teamDataLoading) {
    return <Loading />
  }

  return (
    <>
      {adminData.seasonHasNotStarted() ? (
        <Result 
          title="Unauthorised Access" 
          subTitle="You cannot view another team before the season has started"
          status="warning" 
          icon={<EyeInvisibleOutlined />}
        />
      ) : (
        <>
          <Row type="flex" align="middle">
            <Col xs={24} md={12}>
                <Title level={3} style={{ marginBottom: 0 }}>
                  Team of the Week
                </Title>
            </Col>
          </Row>
          <Divider plain={false} style={{backgroundColor: token.colorPrimary}} />
          <FccSection>
            <Table 
              rowKey={record => record.id}
              columns={columns} 
              dataSource={teamData} 
              size="middle" 
              pagination={false} 
              loading={teamDataLoading}
              summary={() => {
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Space size={4}>
                        <Text strong>{totalPoints}</Text>
                      </Space>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )
              }}
              />            
          </FccSection>
        </>
      )}
    </>
  )


}

export default TeamOfTheWeek;